.uppercase {
  text-transform: uppercase;
}
/*-- -------------------------- -->
<---         services             -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #services {
    background-color: var(--background-primary);
    padding: var(--sectionPadding);
    background: linear-gradient(358deg, var(--backround-1) 100%, #ffffff 10%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #services .section-header {
    text-align: center;
    margin-bottom: 2em;
    margin-top: 4em;
  }
  #services .section-header .title-h2 {
    padding-bottom: 2rem;
  }
  #services .section-header .cs-topper {
    padding-bottom: 4rem;
  }
  #services .container-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 2.4rem;
    max-width: 1200px;
    width: 100%;
  }
  #services .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: calc(25% - 22px);
    /* Vier Bilder pro Reihe */
    min-width: 2.4rem;
    background-color: white;
    border-radius: 15px;
    overflow: hidden;
    text-align: center;
    position: relative;
    /* Für den Pfeil */
    transition: transform 0.7s;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  #services .card a {
    text-decoration: none;
    /* Entfernt die Standard-Link-Dekoration */
    width: 100%;
  }
  #services .card .cs-text .subtitle {
    font-size: 1.2rem;
    letter-spacing: 0.2em;
    color: var(--gray-1);
  }
  #services .card .cs-text .subtitle:before {
    display: inline-block;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: var(--primary-1);
  }
  #services .card .cs-text .p1 {
    font-weight: 700;
    line-height: 1.5em;
    font-size: clamp(1.7rem, 4vw, 2rem);
  }
  #services .card .image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  #services .card .image-wrapper .cs-picture {
    width: 16rem;
    height: 16rem;
    position: relative;
    align-self: center;
    overflow: hidden;
    border-radius: 50%;
  }
  #services .card .image-wrapper .cs-picture img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.7s;
  }
  #services .card .shape-mask {
    right: -3px;
    width: 8.5rem;
    position: absolute;
    bottom: -6px;
    z-index: 0;
  }
  #services .card .shape-mask img {
    height: auto;
    width: 100%;
  }
  #services .card .arrow-btn {
    width: 5rem;
    height: 5rem;
    background-color: var(--primary-2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* Transición suave para el cambio de color del botón */
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 3;
  }
  #services .card .arrow-btn .arrow-img {
    transition: filter 0.3s ease;
    /* Suaviza el cambio de color del SVG */
  }
  #services .card .arrow-btn .arrow-img img {
    width: 100%;
    height: auto;
  }
  #services .card .arrow-btn .fa-solid {
    color: var(--primary-1);
  }
  #services .card .arrow-btn:hover {
    background-color: var(--primary-1);
    /* Nuevo color del círculo en hover */
  }
  #services .card .arrow-btn:hover .fa-solid {
    color: var(--primary);
  }
  #services .card:hover {
    transform: scale(1.05);
  }
  #services .card:hover .cs-picture img {
    transform: scale(1.1);
    /* Picture hover effect */
  }
  #services .card-shop {
    background-color: #D3EBFC;
  }
  #services .card-shop .image-wrapper {
    border-radius: 0%;
  }
  #services .card-shop .image-wrapper .cs-picture2 {
    width: 26rem;
    border-radius: 0%;
    overflow: visible;
  }
}
@media only screen and (min-width: 0rem) and (max-width: 1100px) {
  #services .card {
    width: calc(33.33% - 20px);
    /* Drei Bilder pro Reihe */
  }
}
@media only screen and (min-width: 0rem) and (max-width: 768px) {
  #services .card {
    width: calc(50% - 15px);
    /* Zwei Bilder pro Reihe */
  }
}
@media only screen and (min-width: 0rem) and (max-width: 600px) {
  #services .card {
    width: 100%;
    /* Ein Bild pro Reihe */
  }
}
/* DESKTOP 1024 */
@media only screen and (min-width: 1024px) {
  #services {
    background: linear-gradient(358deg, var(--backround-1) 93%, #ffffff 10%);
  }
  #services .container-cards {
    gap: 2.9rem;
  }
  #services .container-cards .card {
    padding-top: 3rem;
    padding-bottom: 4rem;
  }
  #services .container-cards .image-wrapper {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}
/*-- -------------------------- -->
<---         SBS - NORMAL - UBER MICH            -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #sbs-normal .cs-container .cs-image-group {
    position: relative;
  }
  #sbs-normal .cs-container .cs-image-group .floating-corner-img {
    position: absolute;
    top: -43px;
    right: -42px;
    width: 14.6rem;
    height: auto;
    z-index: 2;
  }
  #sbs-normal .cs-container .cs-image-group .floating-corner-img .cs-corner-img img {
    width: 100%;
    height: auto;
  }
  #sbs-normal .cs-container .cs-image-group .cs-right {
    z-index: -3;
    border-radius: 30px;
    max-height: 47rem;
  }
  #sbs-normal .cs-container .cs-image-group .cs-right img {
    width: 100%;
    height: auto;
  }
  #sbs-normal .cs-container .cs-image-group .cs-box {
    max-width: 25.3rem;
    font-size: clamp(1.3rem, 3.4vw, 1.8rem);
    /* background-color: var(--backround-2); */
    font-family: 'Barlow Semi Condensed';
    text-transform: uppercase;
    line-height: 1.4em;
    background: #E7F5FD;
    height: 25.3rem;
    width: 25.3rem;
    text-align: center;
    align-content: center;
    border-radius: 100%;
    border: 7px solid #ffffff;
    box-sizing: border-box;
    position: absolute;
    z-index: 5;
    bottom: -8.6rem;
    left: -6rem;
  }
}
/* Mobile - 500px */
@media only screen and (min-width: 500px) {
  #sbs-normal .cs-container {
    gap: 10rem;
  }
}
@media only screen and (min-width: 1024px) {
  #sbs-normal .cs-container {
    gap: clamp(3.25rem, 3.1vw, 8rem);
  }
  #sbs-normal .cs-container .cs-image-group {
    margin-left: 4rem;
  }
  #sbs-normal .cs-container .cs-image-group .cs-right {
    max-height: none;
  }
}
@media only screen and (min-width: 1400px) {
  #sbs-normal .cs-container .cs-image-group {
    margin-left: 0rem;
  }
}
/*-- -------------------------- -->
<---           Steps            -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #steps-erste {
    padding: var(--sectionPadding);
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0px;
    background-color: var(--backround-2);
  }
  #steps-erste .cs-container {
    width: 100%;
    max-width: 58.4rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: clamp(2.4rem, 6.4vw, 3.2rem);
  }
  #steps-erste .section-header {
    text-align: center;
    width: 100%;
    padding: 0 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #steps-erste .cs-text {
    max-width: 52.2rem;
  }
  #steps-erste .cs-card-group {
    margin: 0;
    padding: 0;
    display: grid;
    align-items: stretch;
    grid-auto-flow: row;
  }
  #steps-erste .cs-item {
    list-style: none;
    box-sizing: border-box;
    padding: 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
  #steps-erste .cs-item::after {
    content: "";
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #bababa 50%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  #steps-erste .cs-item:first-of-type {
    padding-top: 0;
  }
  #steps-erste .cs-item:last-of-type {
    padding-bottom: 0;
  }
  #steps-erste .cs-item:last-of-type::after {
    content: none;
  }
  #steps-erste .cs-number {
    font-size: clamp(8rem, 1vw, 10rem);
    font-weight: 900;
    line-height: 1.2em;
    color: var(--primary-1);
    opacity: 0.1;
    display: block;
  }
  #steps-erste .title-h3 {
    font-size: clamp(2rem, 3.2vw, 2.5rem);
    line-height: 1.2em;
    margin: -3.1rem 0 1.6rem 0;
    color: var(--headerColor);
    padding-bottom: 0px;
  }
  #steps-erste .cs-item-text {
    font-size: clamp(1.4rem, 3.2vw, 1.6rem);
    line-height: 1.5em;
    text-align: left;
    margin: 0;
    color: var(--bodyTextColor);
  }
}
@media only screen and (min-width: 850px) {
  #steps-erste .cs-container {
    max-width: 124rem;
  }
  #steps-erste .cs-card-group {
    grid-template-columns: repeat(4, 1fr);
    gap: 0;
  }
  #steps-erste .cs-item {
    padding: clamp(2.4rem, 2vw, 6rem);
  }
  #steps-erste .cs-item::after {
    width: 1px;
    height: 100%;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #bababa 50%, rgba(255, 255, 255, 0) 100%);
    opacity: 0.6;
    right: 0;
    left: initial;
  }
  #steps-erste .cs-item:first-of-type,
  #steps-erste .cs-item:last-of-type {
    padding: clamp(2.4rem, 2vw, 6rem);
  }
}
/*-- -------------------------- -->
<---       VIDEO EMBED         -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0em) {
  #video-embed {
    background: linear-gradient(to bottom, var(--backround-2) 70%, white 30%);
  }
  #video-embed .cs-container {
    max-width: 1200px;
    /* Maximum width of the container */
    padding: 37px 0px;
    margin: -26px;
  }
  #video-embed .cs-container .video-container {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
    /* Background color for video container */
    border-radius: 10px;
    /* Rounded corners for the container */
    max-width: 129.2rem;
  }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
/* DESKTOP 1157px */
@media only screen and (min-width: 1157px) {
  #video-embed .cs-container {
    margin: auto;
  }
}
/* Standardmäßig das 480p-Video anzeigen */
.video-720p {
  display: none;
}
@media (min-width: 700px) {
  /* Für größere Bildschirme das 720p-Video anzeigen und das 480p-Video ausblenden */
  .video-480p {
    display: none;
  }
  .video-720p {
    display: block;
  }
}
/*-- -------------------------- -->
<---          Reviews           -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0px) {
  #reviews {
    margin-top: clamp(9rem, 24vw, 12rem);
    margin-bottom: clamp(9rem, 24vw, 12rem);
  }
}
/*-- -------------------------- -->
<---      SBS-gebuehren      -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0px) {
  #sbs-gebuehren {
    background-color: var(--background-primary);
    padding: var(--sectionPadding);
    background: linear-gradient(358deg, var(--backround-1) 100%, #ffffff 10%);
    padding-top: clamp(3rem, 10.3vw, 14.2rem);
  }
  #sbs-gebuehren .cs-container {
    width: 100%;
    max-width: 55rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  #sbs-gebuehren .cs-container ul {
    list-style-position: inside;
  }
  #sbs-gebuehren .cs-container .p3 {
    color: var(--bodyTextColor);
  }
  #sbs-gebuehren .cs-container h3 {
    padding-top: 4rem;
    padding-bottom: 2.4rem;
  }
  #sbs-gebuehren .cs-container .section-header {
    text-align: center;
    margin-bottom: 2em;
    margin-top: 4em;
  }
  #sbs-gebuehren .cs-container .cs-right {
    width: 100%;
    max-width: 48.2rem;
    min-height: clamp(25rem, 68vw, 37.5rem);
    position: relative;
    align-self: center;
  }
  #sbs-gebuehren .cs-container .cs-picture img {
    width: 100%;
    height: auto;
    display: block;
  }
  #sbs-gebuehren .cs-container .cs-left {
    max-width: 54rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  #sbs-gebuehren .cs-container .cs-button-solid {
    margin-right: 1rem;
  }
}
@media only screen and (min-width: 1024px) {
  #sbs-gebuehren {
    padding-top: 2rem;
    background: linear-gradient(358deg, var(--backround-1) 94%, #ffffff 10%);
  }
  #sbs-gebuehren .cs-container {
    max-width: 124rem;
  }
  #sbs-gebuehren .cs-container .section-header {
    margin-top: 14rem;
  }
  #sbs-gebuehren .cs-container .flex-wrapper {
    display: flex;
    flex-direction: row;
    gap: clamp(1.5rem, 5.3vw, 10vw);
  }
  #sbs-gebuehren .cs-container .flex-wrapper .cs-right {
    max-width: 47.8rem;
  }
}
/*-- -------------------------- -->
<---      cards   blog          -->
<--- -------------------------- -*/
@media only screen and (min-width: 0px) {
  #home-blog {
    padding: var(--sectionPadding);
    background-color: #F7FBFF;
  }
  #home-blog .cs-container {
    width: 100%;
    max-width: 119rem;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: clamp(1.5rem, 1.5vw, 4rem);
  }
  #home-blog .container-cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    overflow-x: scroll;
    gap: 1.6rem;
    width: 100%;
    padding-bottom: 4rem;
    align-items: stretch;
    overflow-y: hidden;
  }
  #home-blog .card {
    width: 100%;
    min-width: 21.9rem;
    max-width: 35.8rem;
    background-color: white;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    transition: border-color 0.3s ease, transform 0.7s;
    overflow: hidden;
    box-shadow: var(--shadow-md);
  }
  #home-blog .card:hover {
    transform: scale(1.05);
    overflow: hidden;
    border-radius: 30px;
  }
  #home-blog .card:hover .cs-picture img {
    transform: scale(1.1);
    /* Picture hover effect */
  }
  #home-blog .card .cs-link {
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
  }
  #home-blog .card .cs-picture-container {
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    max-height: 39rem;
  }
  #home-blog .card .cs-picture-container .cs-picture {
    width: clamp(3.6rem, 100vw, 1300rem);
    height: auto;
    position: relative;
    align-self: center;
    overflow: hidden;
  }
  #home-blog .card .cs-picture-container img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.7s;
  }
  #home-blog .card .card-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: clamp(3rem, 4.2rem, 2.7rem) clamp(2rem, 4.5rem, 3rem);
    gap: 2rem;
    height: 19rem;
    /* Para que todos los botones queden a la misma altura */
    /* Botón redondo con flecha para el blog */
  }
  #home-blog .card .card-info .card-title {
    font-family: inter;
    text-transform: none;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Número de líneas a mostrar */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    font-weight: 400;
    max-height: 4.2em;
    /* Multiplica la altura de la línea por el número de líneas */
  }
  #home-blog .card .card-info .read-more {
    display: flex;
    align-items: center;
  }
  #home-blog .card .card-info .read-more .cs-button-link {
    margin-right: 5px;
  }
  #home-blog .card .card-info .read-more .arrow-btn {
    position: relative;
    width: 3rem;
    height: 3rem;
    background-color: var(--primary-2);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  #home-blog .card .card-info .read-more .arrow-btn .arrow-img {
    width: 15px;
    height: 15px;
    transition: filter 0.3s ease;
    /* Suaviza el cambio de color del SVG */
  }
  #home-blog .card .card-info .read-more .arrow-btn .arrow-img img {
    width: 100%;
    height: auto;
  }
  #home-blog .card .card-info .read-more .arrow-btn:hover {
    background-color: var(--primary-1);
    /* Cambia el color del círculo en hover */
  }
  #home-blog .card .card-info .read-more .arrow-btn:hover .arrow-img {
    filter: invert(1);
    /* Cambia el color de la flecha a blanco en hover */
  }
}
@media only screen and (min-width: 768px) {
  #home-blog .container-cards {
    overflow-x: hidden;
  }
}
/*-- -------------------------- */
/*--         SHOP         */
/*-- -------------------------- */
/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #sbs-normal-2 .cs-container .cs-image-group {
    position: relative;
    width: 100%;
  }
  #sbs-normal-2 .cs-container .cs-image-group .cs-right {
    justify-self: center;
  }
  #sbs-normal-2 .cs-container .cs-image-group .blue-badge {
    position: absolute;
    z-index: 5;
    bottom: -1.6rem;
    left: -1rem;
    width: clamp(10rem, 26.6vw, 17rem);
    height: clamp(10rem, 26.6vw, 17rem);
  }
  #sbs-normal-2 .cs-container .cs-image-group .blue-badge .blue-badge-pic {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #sbs-normal-2 .cs-container .cs-image-group .blue-badge .blue-badge-pic img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}
@media only screen and (min-width: 1024px) {
  #sbs-normal-2 .cs-container {
    flex-flow: row-reverse;
  }
}
/*-- -------------------------- -->
<---      KONTAKT      -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0px) {
  #cta-with-map .cs-container {
    flex-direction: column;
  }
}
/* Desktop 1024px */
@media only screen and (min-width: 1024px) {
  #cta-with-map .cs-container {
    flex-direction: row;
  }
}
/*-- -------------------------- -->
<---      KONTAKT      -->
<--- -------------------------- -*/
#cta-with-map {
  background-color: var(--backround-1);
}
/*# sourceMappingURL=local.css.map */